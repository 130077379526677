<template>
  <div class="el_enterprise">
    <div class="rzlc">
      <div class="wrapper">
        <div class="rzlc-box">
          <div class="mtit small">
            <h2 class="cn">产业园入驻申请流程</h2>
            <div class="en">Industrial Park Residency Application Process</div>
          </div>
          <div class="rzlc-list-box">
            <ul class="rzlc-list">
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon41.png" /></div>
                  <h3>企业（机构）</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon42.png" /></div>
                  <h3>提交入驻申请表及相关材料</h3>
                </div>
                <span class="m"></span>
              </li>
              <li class="on">
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon43.png" /></div>
                  <h3>提交产业园建设领导小组审评</h3>
                </div>
                <span class="m"></span>
                <ul class="sublist">
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon49.png" /></div>
                      <h3>不符合入驻要求</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon50.png" /></div>
                      <h3>拒绝入驻</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                </ul>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon44.png" /></div>
                  <h3>符合要求，确定面积和位置签订入驻协议</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon45.png" /></div>
                  <h3>企业（机构）入驻相关材料备案</h3>
                </div>
                <span class="m"></span>
              </li>
              <li class="on">
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon46.png" /></div>
                  <h3>园区定期考核</h3>
                </div>
                <span class="m"></span>
                <ul class="sublist">
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon51.png" /></div>
                      <h3>考核不合格</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon52.png" /></div>
                      <h3>提前终止协议</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                </ul>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon47.png" /></div>
                  <h3>考核合格，享受支持政策</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon48.png" /></div>
                  <h3>企业（机构）续约入驻</h3>
                </div>
                <span class="m"></span>
              </li>
            </ul>
            <div class="gjrcc-btn">
              <a @click="toRouter('apply',{cn:'产业园入驻申请',en:'Industrial park entry application',type:7})"
                 class="mfullbtn modifyBgc"><img src="../../../assets/images/icon32.png" />咨询入驻</a>
            </div>
          </div>
        </div>
        <div class="rzlc-box">
          <div class="mtit small">
            <h2 class="cn">人才城入驻申请流程</h2>
            <div class="en">Talent city Resident application process</div>
          </div>
          <div class="rzlc-list-box">
            <ul class="rzlc-list">
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon41.png" /></div>
                  <h3>企业（机构）</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon42.png" /></div>
                  <h3>提交入驻申请表及相关材料</h3>
                </div>
                <span class="m"></span>
              </li>
              <li class="on">
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon43.png" /></div>
                  <h3>提交产业园建设领导小组审评</h3>
                </div>
                <span class="m"></span>
                <ul class="sublist">
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon49.png" /></div>
                      <h3>不符合入驻要求</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon50.png" /></div>
                      <h3>拒绝入驻</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                </ul>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon44.png" /></div>
                  <h3>符合要求，确定面积和位置签订入驻协议</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon45.png" /></div>
                  <h3>企业（机构）入驻相关材料备案</h3>
                </div>
                <span class="m"></span>
              </li>
              <li class="on">
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon46.png" /></div>
                  <h3>园区定期考核</h3>
                </div>
                <span class="m"></span>
                <ul class="sublist">
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon51.png" /></div>
                      <h3>考核不合格</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                  <li>
                    <div class="item">
                      <div class="icon"><img src="../../../assets/images/icon52.png" /></div>
                      <h3>提前终止协议</h3>
                    </div>
                    <span class="m"></span>
                  </li>
                </ul>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon47.png" /></div>
                  <h3>考核合格，享受支持政策</h3>
                </div>
                <span class="m"></span>
              </li>
              <li>
                <div class="item">
                  <div class="icon"><img src="../../../assets/images/icon48.png" /></div>
                  <h3>企业（机构）续约入驻</h3>
                </div>
                <span class="m"></span>
              </li>
            </ul>
            <div class="gjrcc-btn">
              <a class="mfullbtn modifyBgc"
                 @click="toRouter('apply',{cn:'人才城入驻申请',en:'Talent City entry application',type:8})"><img src="../../../assets/images/icon32.png" />咨询入驻</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mrow rzqy">
      <componeny type="2" />
    </div>

    <div class="mrow hzqy">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">合作企业</h2>
          <div class="en">joint venture</div>
          <div class="line"></div>
        </div>
        <div class="hzqy-list">
          <ul>
            <li v-for="(item,index) in list"
                :key="index">
              <a>
                <div class="img"
                     style="width:100%;height:100%;">
                  <img :src="item.logo"
                       style="height:90%;" />
                </div>
                <div class="txt showContent"
                     v-html="item.companyProfile">
                  <!-- {{item.companyProfile}} -->
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="hzqy-more">
          <a @click="toRouter('cooperation')"
             class="mbtn orange ful">查看全部</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import componeny from '../../components/componeny.vue'
export default {
  data () {
    return {
      msg: 'el_enterprise',
      list: [
      ],
    }
  },
  components: {
    componeny
  },
  mounted () {
    this.getList();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };
      this.newRouter('/' + routerName, query)
    },
    getList () {
      this.api.cooperativeList({ pageNo: 1, pageSize: 50 }).then(res => {
        this.list = res.data.result.records;
      })
    },

  }
}
</script>
<style scoped lang="scss">
@media screen and (max-width: 1201px) {
  .showContent {
      overflow-y:auto
  }
  .modifyBgc {
    background-color: #FDAE01;
  }
}

</style>
